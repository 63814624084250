.image_1 {
  margin-top: -70px;
  margin-left: -60px;
}
.image_2 {
  margin-top: -100px;
  margin-left: 80px;
}
.image_3 {
  margin-top: -100px;
  margin-left: -60px;
}

@media (max-width: 600px) {
  .image_1,
  .image_2,
  .image_3 {
    width: 80vw;
    height: auto;
    margin: auto;
    margin-left: 25px;
  }
}

/* .iphone{
 
  margin-top: 500px;
  margin-bottom: 200px;

  @media (max-width:600px) {
    margin-top: 600px;
   }
} */

.faq {
  font-size: 45px;
  font-weight: bold;
  margin-top: 100px;
  text-align: center;
}

/* Add this CSS to your stylesheet */
.copyright {
  color: white;
  height: 100px;
  display: flex;
  justify-content: space-around; /* This will distribute space around your items evenly */
  align-items: center; /* This will center them vertically */
  flex-wrap: wrap; /* This allows items to wrap onto the next line if there is not enough space */
  background-color: #101031;

  @media (max-width: 600px) {
    width: 100vw;

    height: 15rem;
  }
}

.titleT {
  @media (max-width: 600px) {
  }
}

.title_test {
  color: rgb(255, 0, 0);
}

@media (max-width: 600px) {
  .title_test {
    margin-left: 40px;
    text-align: left;
  }
}

@media (max-width: 600px) {
  .mainlogo {
  }
}

/* Ensure the paragraphs don't take full width to allow them to sit next to each other */
copyright p {
  margin: 0; /* This removes the default margin from paragraphs */
  flex: 1; /* This allows paragraphs to grow and take up equal space */
}

.titlemain {
  font-size: 45px;
  font-weight: 800;
  margin-top: -30rem;
  @media (max-width: 600px) {
    font-size: 35px;
    text-align: left;

    margin-top: -190vw;
  }
}

@media (max-width: 600px) {
  .btns {
    background-color: aqua;
  }
}

.feeter {
  @media (max-width: 600px) {
  }
}

.desc_h {
  text-align: center;
  font-size: 20px;
  margin-bottom: 100px;
  @media (max-width: 600px) {
    text-align: justify;
    font-size: 18px;
  }
}

.how {
  margin-top: -100px;
  text-align: center;
}

@media (max-width: 769px) {
  .how {
    margin-top: 500px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}

@media (max-width: 601px) {
  .how {
    margin-top: 1200px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}

@media (max-width: 567px) {
  .how {
    margin-top: 1100px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}

@media (max-width: 431px) {
  .how {
    margin-top: 770px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}

@media (max-width: 391px) {
  .how {
    margin-top: 750px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}

@media (max-width: 321px) {
  .how {
    margin-top: 600px;
  }

  .footlogo {
    margin-top: 20px;
    width: 150px;
    height: auto;
  }
}
