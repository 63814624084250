

.dots {

  position: absolute;
  margin-top: 100px;
  color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));

  @media (max-width: 48em) {
    display: none;
  }
}

.dotsLeft {
  left: 0;
  top: 0;
}

.wrapper {
  /* Change to relative */
  font-size: 20px;
  background-color: rgb(255, 255, 255);
  margin-top: 40px;
  width: 100%; /* Change to 100% */
  height: auto; /* Change to auto */
  margin-left: 0; /* Remove negative margin */
}

.inner {
  margin-top: 100px;
  position: relative;
  z-index: 1;
}
.highlight{
  color: #009598;
}
.title {
  text-align: center;
  font-weight: 800;
  font-size: 55px;

  margin-bottom: 20px;
  font-family: Greycliff CF, var(--mantine-font-family);
}

.description {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%; /* Set width to 100% */

  /* Remove the mobile-specific styles */
}

.controls {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
}

.herob1,
.herob2 {
 
  padding: 0;
  height: 50px;
  margin: 0 10px; /* Add margin between buttons */
  border-radius: 3px;
  background-color: #009598;
  font-weight: 700;
  border: none;
  width: 15rem;
}
.herob1 a,
.herob2 a {
  text-decoration: none;

  color: white;
}


/* Add mobile-specific styles for buttons */
@media (max-width: 600px) {
  .wrapper{
    overflow-x: hidden;
    width: 100%;
    position: relative;
  
  }
  .herob1,
  .herob2 {
    margin: 5px;
    font-size: 22px;
  
    background-color: #009598;
    height: 40px;
    width: calc(100% - 20px); /* Make buttons take 50% width with margin */
  }

  .herob1 a, .herob2 a{
    text-decoration: none;
    color: white;
  }
.title{
  margin-top: -70px;
  font-size: 35px;
  overflow-x: hidden;
}
  .description{
    font-size: 18px;
    overflow-x: hidden;
  }

  .highlight{
    color: #009598;
  }
}
